import React from "react";
import {
    ArrayInput,
    FormDataConsumer,
    NumberInput,
    required,
    SelectInput,
    SimpleFormIterator,
    TextInput
} from "react-admin";
import Button from "@mui/material/Button";
import TariffKey from "../../components/input/TariffKey";
import CurrencyInput from "../../components/input/CurrencyInput";
import { alpha } from '@mui/material';
import {colors} from "../../constants";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { Stack } from '@mui/material';
import Typography from "@mui/material/Typography";

export const Tarifffields = () => {
    return <>
        <TextInput source="tariffgroup_id" validate={required()}/>
        <Typography variant="h6">Subtariffs</Typography>
        <SubTariffsInput />
    </>
}

const SubTariffsInput = () => {
    return (<ArrayInput source="subtariffs" name={"subtariffs"} label={false}>
        <SimpleFormIterator addButton={<Button variant={'outlined'}>Add subtariff</Button>} removeButton={<Button>Remove subtariff</Button>}>
            <FormDataConsumer>
                {({
                      scopedFormData, // The data for this item of the ArrayInput
                      getSource, // A function to get the valid source inside an ArrayInput
                      })=> scopedFormData && getSource ? <Accordion sx={{marginY:1}}>
                    <AccordionSummary expandIcon={<ArrowDownwardIcon />}
                                      aria-controls="panel1-content"
                                      id="panel1-header">{scopedFormData.tariff?.id ?? 'NEW'}</AccordionSummary>
                    <AccordionDetails>
                        <Stack alignItems="flex-start">
                            <TariffKey source={getSource("tariffkey")} validate={required()} />
                            <TextInput source={getSource("tariff.id")} validate={required()}/>
                            <CurrencyInput source={getSource("tariff.currency")} validate={required()} defaultValue={"EUR"}/>
                            <Typography variant="h6">Price components</Typography>
                            <TariffElements source={getSource("tariff.elements")} disableAdd={(scopedFormData.tariff?.elements?.length ?? 0) > 0} />
                        </Stack>
                    </AccordionDetails>
                </Accordion> : null
                }
            </FormDataConsumer>
        </SimpleFormIterator>
    </ArrayInput>);
}
const TariffElements = ({source, disableAdd} : {source: string, disableAdd: boolean}) => {
    return (<ArrayInput source={source} label={false} >
        <SimpleFormIterator
            disableAdd={disableAdd}
            addButton={<Button variant={'outlined'}>Add tariff element</Button>} removeButton={<Button>Remove tariff element</Button>}
            sx={{
                '.RaSimpleFormIterator-line': {
                    paddingLeft: '8px',
                    marginY: '8px',
                    backgroundColor: alpha(colors.e55cLightBlue, 0.1)
                }
            }}>
            <PriceComponentsInput source="price_components" />
        </SimpleFormIterator>
    </ArrayInput>);
}


const PriceComponentsInput = ({source} : {source: string}) => {
    return (<ArrayInput source={source} label={false}>
        <SimpleFormIterator inline addButton={<Button>Add price component</Button>} removeButton={<Button>Remove price component</Button>}
                            sx={{
                                '.RaSimpleFormIterator-line': {
                                    paddingLeft: '8px',
                                    backgroundColor: alpha(colors.e55cLightBlue, 0.1)
                                }
                            }}>
            <NumberInput source="price" validate={required()} />
            <NumberInput source="step_size" validate={required()} step={1}/>
            <SelectInput source="type" validate={required()} choices={[{id: "TIME", name: "TIME"}, {id: "PARKING_TIME", name: "PARKING_TIME"}, {id: "ENERGY", name: "ENERGY"}, {id: "FLAT", name: "FLAT"}]} sx={{marginTop: 0}}/>
        </SimpleFormIterator>
    </ArrayInput>)
}
