import {SelectInput} from "react-admin";

const BadgeType = ({choices, ...rest}: any) => {
    return <SelectInput choices={[
        { id: 'RFID', name: 'RFID' },
        { id: 'OTHER', name: 'OTHER' },
    ]} {...rest}>
    </SelectInput>
}

export default BadgeType;
