import {Typography} from "@mui/material";
import * as React from "react";
import Box from "@mui/material/Box";
import {ReactNode} from "react";

export default function DashboardSection({title, children}: { title: string, children: ReactNode }) {
    return (<Box marginY={3}>
        <Typography variant={'h3'} marginBottom={2}>{title}</Typography>
        <Box sx={{display: "flex", flexDirection: {xs: "column", sm: "row"}, gap: 2}}>{children}</Box>
    </Box>);
}
