import {
    Show,
    TextField,
    TopToolbar,
    ListButton,
    useRecordContext,
    ArrayField,
    Datagrid,
    FunctionField,
    DateField, NumberField,
} from 'react-admin';

import {ChevronLeft} from '@mui/icons-material';
import React from "react";
import {Typography} from "@mui/material";
import {Issuer} from "../../../data/facturation/issuer";
import Box from "@mui/material/Box";
import {Link} from "react-router-dom";
import DownloadInvoiceButton from "./widgets/DownloadInvoiceButton";
import SendInvoiceButton from "./widgets/SendInvoiceButton";

const Actions = () => {
    return (
        <TopToolbar>
            <ListButton icon={<ChevronLeft/>}/>
        </TopToolbar>
    );
};

export const IssuerShow = () => (
    <Show actions={<Actions/>} emptyWhileLoading>
        <Content />
    </Show>
);

const Content = () => {
    const record = useRecordContext<Issuer>();

    return (<Box sx={{paddingX: 3}}>
        <Typography variant={'h4'} sx={{marginBottom: 3}}>{record.reference}</Typography>

        {record.billingInfo && <Section title={'Données de facturation'}>
            <TextField source={'billingInfo.contact'} label={'contact email'}/>
        </Section>}

        <Section title={'Périodes'}>
            <ArrayField source="invoices">
                <Datagrid bulkActionButtons={false}>
                    <FunctionField label="Période" render={(invoice: any) => `${invoice.period.year}-${invoice.period.month + 1}`}/>
                    <NumberField source="totalCost" label={'Cout total'} options={{ style: 'currency', currency: 'EUR' }}/>
                    <NumberField source="totalEnergy" label={'Énergie totale'} options={{ maximumFractionDigits: 2 }}/>
                    <NumberField source="totalTime" label={'Temps total'} options={{ maximumFractionDigits: 2 }}/>
                    <FunctionField source="cdrsIds" label={'Cdrs'} textAlign={"right"} render={(invoice: any) => <Link to={{
                        pathname: '/cdrs',
                        search: `filter=${JSON.stringify({ issuer: record.reference, invoice_id: invoice.id })}`,
                    }}>{invoice.cdrsIds.length}</Link>}/>
                    <FunctionField label={'Télécharger'} render={(invoice: any) => <DownloadInvoiceButton issuerId={record.id} invoiceId={invoice.id}/>}/>
                    <FunctionField label={'Envoyer'} render={(invoice: any) => <SendInvoiceButton issuerId={record.id} invoiceId={invoice.id}/>}/>
                    <DateField source="created" showTime={true} locales={'fr-FR'} label={'Date de création'}/>
                </Datagrid>
            </ArrayField>
        </Section>
    </Box>)
}

const Section = ({title, children} : {title: string, children?: React.ReactNode}) => {
    return <Box>
        <Typography variant={'h5'}>{title}</Typography>
        {children}
    </Box>
}
