import {defaultTheme} from "react-admin";
import {Yess55Theme} from "./yes55/theme";
import {E55cTheme} from "./e55c/theme";
import {GcaTheme} from "./gca/theme";
import appConfig from "../../config";
import {createTheme} from "@mui/material/styles";

const tenant = appConfig.TENANT;

let appTheme = defaultTheme;

switch (tenant) {
    case 'yes55':
        appTheme = Yess55Theme;
        break;
    case 'e55c':
        appTheme = E55cTheme;
        break;
    case 'gca':
        appTheme = GcaTheme;
        break;
}

appTheme = createTheme(appTheme, {
    components: {
        MuiAppBar: {
            styleOverrides: {
                root: {
                    background: '#ffffff',
                    color: '#000000',
                },
            },
        },
        RaMenuItemLink: {
            styleOverrides: {
                root: {
                    // invisible border when not active, to avoid position flashs
                    borderLeft: '3px solid transparent',
                    '&.RaMenuItemLink-active': {
                        borderLeft: `3px solid`,
                        // @ts-ignore
                        borderColor: appTheme.palette?.primary.main
                    },
                },
            },
        },
    }
});

export default appTheme;
