declare global {
    interface Window {
        REACT_APP_COGNITO_USERPOOL_ID:any;
        REACT_APP_COGNITO_APP_CLIENT_ID:any;
        REACT_APP_COGNITO_APP_HOSTED_UI_URL:any;
        REACT_APP_AWS_API_URL:any;
        REACT_APP_APP_NAME:any;
        REACT_APP_TARIFFS_ENABLED:any;
        REACT_APP_TENANT:any;
        REACT_APP_TARIFF_KEYS: any;

        COGNITO_USERPOOL_ID:any;
        COGNITO_APP_CLIENT_ID:any;
        COGNITO_APP_HOSTED_UI_URL:any;
        AWS_API_URL:any;
        APP_NAME:any;
        TARIFFS_ENABLED:any;
        TENANT:any;
        TARIFF_KEYS: any;
    }
}

type AppConfig = {
    TENANT: string,
    COGNITO_USERPOOL_ID: string,
    COGNITO_APP_CLIENT_ID: string,
    COGNITO_APP_HOSTED_UI_URL: string,
    AWS_API_URL: string,
    APP_NAME: string,
    TARIFFS_ENABLED: string,
    TARIFF_KEYS: string,
}

const appConfig : AppConfig = {
    TENANT: process.env.REACT_APP_TENANT || window.REACT_APP_TENANT || window.TENANT || '',
    COGNITO_USERPOOL_ID: process.env.REACT_APP_COGNITO_USERPOOL_ID || window.REACT_APP_COGNITO_USERPOOL_ID || window.COGNITO_USERPOOL_ID || '',
    COGNITO_APP_CLIENT_ID: process.env.REACT_APP_COGNITO_APP_CLIENT_ID || window.REACT_APP_COGNITO_APP_CLIENT_ID || window.COGNITO_APP_CLIENT_ID || '',
    COGNITO_APP_HOSTED_UI_URL: process.env.REACT_APP_COGNITO_APP_HOSTED_UI_URL || window.REACT_APP_COGNITO_APP_HOSTED_UI_URL || window.COGNITO_APP_HOSTED_UI_URL || '',
    AWS_API_URL: process.env.REACT_APP_AWS_API_URL || window.REACT_APP_AWS_API_URL || window.AWS_API_URL || '',
    APP_NAME: process.env.REACT_APP_APP_NAME || window.REACT_APP_APP_NAME || window.APP_NAME || '',
    TARIFFS_ENABLED: process.env.REACT_APP_TARIFFS_ENABLED || window.REACT_APP_TARIFFS_ENABLED ||  window.TARIFFS_ENABLED || "0",
    TARIFF_KEYS: process.env.REACT_APP_TARIFF_KEYS || window.REACT_APP_TARIFF_KEYS || window.TARIFF_KEYS || ["AC_22KW","DC_50KW"],
};

export default appConfig;
