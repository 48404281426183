import { defaultTheme } from 'react-admin';
import { createTheme } from '@mui/material/styles';


export const E55cTheme = {
    ...defaultTheme,
    ...createTheme({palette: {
            primary: {
                main: '#005db7'
            },
        }
    }),
};
