import { defaultTheme } from 'react-admin';
import {createTheme} from "@mui/material/styles";

export const Yess55Theme= {
     ...defaultTheme,
     ...createTheme({palette: {
             primary: {
                 main: '#248638'
             }
         }
     }),
 };
