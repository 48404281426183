import { defaultTheme } from 'react-admin';
import {createTheme} from "@mui/material/styles";

export const GcaTheme = {
    ...defaultTheme,
    ...createTheme({palette: {
            primary: {
                main: '#485158'
            },
            secondary: {
                main: '#ef2035'
            },
        }
    }),
};
