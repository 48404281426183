import {DataProvider, fetchUtils} from 'react-admin';
import { stringify } from 'query-string';
import appConfig from "../config";
import {MonthIssuersStats} from "./dto/MonthIssuersStats";

const apiUrl = appConfig.AWS_API_URL;

const httpClient = (url: string, options : any = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }

    const token = localStorage.getItem('token')

    if (token) {
        options.headers.set('Authorization', `Bearer ${token}`);
    }

    return fetchUtils.fetchJson(url, options).catch((err) => {
        if (err.body && err.body.errorMessage) {
            err.message = err.body.errorMessage;
        }
        return Promise.reject(err)
    });
};

const httpClientPlain = (url: string, options : any = {}) => {
    if (!options.headers) {
        options.headers = new Headers();
    }

    const token = localStorage.getItem('token')

    if (token) {
        options.headers.set('Authorization', `Bearer ${token}`);
    }

    return fetch(url, options).catch((err) => {
        if (err.body && err.body.errorMessage) {
            err.message = err.body.errorMessage;
        }
        return Promise.reject(err)
    });
};

const mapItemFromApi = (resource: string, item: any) => {
    switch (resource) {
        case 'badges':
            return {...item, id: item.id ?? item.SK.substring(4)};

        case 'locations':
            return {...item, id: item.id ?? item.SK.substring(4)};

        case 'tariffgroups':
            return {...item, id: item.tariffgroup_id};

        case 'tarifflinks':
            return {...item, id: item.location_id + item.party_id + item.tariffgroup_id};

        case 'emsps':
        case 'exploitation/emsps':
            return {...item, id: item.party_id};
    }

    return item;
}

export type E55cApiDataProvider = DataProvider & {
    generateInvoices() : Promise<void>,
    downloadInvoice({issuerId, invoiceId}: {issuerId: string, invoiceId: string}): Promise<Response>,
    sendInvoice({issuerId, invoiceId}: {issuerId: string, invoiceId: string}): Promise<Response>,
    getInvoicingConfig(): Promise<any>,
    saveInvoicingConfig(data: any): Promise<any>,
    getPreviousMonthIssuersStats(): Promise<MonthIssuersStats>,
}

const Provider : E55cApiDataProvider = {
    getList: (resource: string, params: any) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([(page - 1) * perPage, page * perPage]),
            filter: JSON.stringify(params.filter),
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;

        return httpClient(url).then(({ headers, json }) => {
            return ({
                data: json.items.map((item: any) => mapItemFromApi(resource, item)),
                total: parseInt(json.total),
            });
        });
    },

    getOne: (resource: string, params: any) => {
        return httpClient(`${apiUrl}/${resource}/${encodeURIComponent(params.id)}`)
            .then(({json}) => ({data: mapItemFromApi(resource, json)}));
    },

    getMany: (resource: string, params: any) => {
        const query = {
            filter: JSON.stringify({ ids: params.ids }),
        };

        let url = `${apiUrl}/${resource}?${stringify(query)}`;

        if (resource === 'locations') {
            url = `${apiUrl}/locations/many?${stringify(query)}`;
        }

        return httpClient(url).then(({ json }) => {
            return ({data: json.items.map((item: any) => mapItemFromApi(resource, item))});
        });
    },

    getManyReference: (resource: string, params: any) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
            filter: JSON.stringify({
                ...params.filter,
                [params.target]: params.id,
            }),
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;

        return httpClient(url).then(({ headers, json }) => ({
            data: json.items.map((item: any) => mapItemFromApi(resource, item)),
            total: parseInt(json.total, 10),
        }));
    },

    create: (resource: string, params: any) => {
        const data = resource === 'tariffgroups' ? filterDataUpdateTariffgroup(params.data) : params.data;

        return httpClient(`${apiUrl}/${resource}`, {
            method: 'POST',
            body: JSON.stringify(data),
        }).then(({json}) => ({data: mapItemFromApi(resource, json)}));
    },

    update: (resource: string, params: any) => {
        const data = resource === 'tariffgroups' ? filterDataUpdateTariffgroup(params.data) : params.data;

        return httpClient(`${apiUrl}/${resource}/${encodeURIComponent(params.id)}`, {
            method: 'PUT',
            body: JSON.stringify(data),
        }).then(({json}) => ({data: mapItemFromApi(resource, json)}));
    },

    updateMany: (resource: string, params: any) => {
        const query = {
            filter: JSON.stringify({ id: params.ids}),
        };
        return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json }));
    },

    delete: (resource: string, params: any) => {
        return httpClient(`${apiUrl}/${resource}/${encodeURIComponent(params.id)}`, {
            method: 'DELETE',
        }).then(({json}) => ({data: json}));
    },

    deleteMany: (resource: string, params: any) => {
        const query = {
            filter: JSON.stringify({ id: params.ids}),
        };
        return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
            method: 'DELETE',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json }));
    },

    deleteTarifflinks: ({location_id, party_id, tariffgroup_id} : {location_id: string, party_id: string, tariffgroup_id: string}) =>
        httpClient(`${apiUrl}/tarifflinks?location_id=${encodeURIComponent(location_id)}&party_id=${encodeURIComponent(party_id)}&tariffgroup_id=${encodeURIComponent(tariffgroup_id)}`, {
            method: 'DELETE',
        }).then(({ json }) => ({ data: json })),

    syncCdrs: ({dateFrom, dateTo}: {dateFrom: string, dateTo: string}) => {
        return httpClient(`${apiUrl}/cdrs-sync`, {
            method: 'POST',
            body: JSON.stringify({dateFrom, dateTo}),
        }).then(({ json }) => ({ data: json }));
    },

    addSyncjob: ({dateFrom, dateTo, emsps}: {dateFrom: string, dateTo: string, emsps?: string[]}) => {
        return httpClient(`${apiUrl}/cdr-import-jobs`, {
            method: 'POST',
            body: JSON.stringify({dateFrom, dateTo, emsps}),
        }).then(({ json }) => ({ data: json }));
    },

    importExploitationLocations: () => {
        return httpClient(`${apiUrl}/locations-import`, {
            method: 'POST',
        }).then(({ json }) => ({ data: json }));
    },

    getOpendataFile: () => {
        return httpClientPlain(`${apiUrl}/opendata/get-file`, {
            method: 'GET',
        });
    },

    getOpendataConfig: () => {
        return httpClient(`${apiUrl}/opendata/config`, {
            method: 'GET',
        }).then(({ json }) => json);
    },

    saveOpendataConfig: (data: any) => {
        return httpClient(`${apiUrl}/opendata/config`, {
            method: 'PUT',
            body: JSON.stringify(data),
        });
    },

    getEmspStats: () : Promise<{badges:number, locations: number}> => {
        return httpClient(`${apiUrl}/emsp/stats`, {
            method: 'GET',
        }).then(({ json }) => json);
    },

    generateInvoices() : Promise<void> {
        return httpClient(`${apiUrl}/invoicing/generate-invoices`, {
            method: 'POST',
        }).then(({ json }) => json);
    },

    downloadInvoice({issuerId, invoiceId}: {issuerId: string, invoiceId: string}) : Promise<any> {
        return httpClientPlain(`${apiUrl}/invoicing/issuers/${issuerId}/invoices/${invoiceId}/download`, {
            method: 'GET',
        });
    },

    sendInvoice({issuerId, invoiceId}: {issuerId: string, invoiceId: string}): Promise<any> {
        return httpClient(`${apiUrl}/invoicing/issuers/${issuerId}/invoices/${invoiceId}/send`, {
            method: 'POST',
        });
    },

    getInvoicingConfig: () => {
        return httpClient(`${apiUrl}/invoicing/config`, {
            method: 'GET',
        }).then(({ json }) => json);
    },

    saveInvoicingConfig: (data: any) => {
        return httpClient(`${apiUrl}/invoicing/config`, {
            method: 'PUT',
            body: JSON.stringify(data),
        });
    },

    getPreviousMonthIssuersStats: async (): Promise<MonthIssuersStats> => {
        const {json} = await httpClient(`${apiUrl}/invoicing/stats/issuers/all/previous-month`, {
            method: 'GET',
        });
        return json;
    },

    getTokensExport: () => {
        return httpClient(`${apiUrl}/badges/export`, {
            method: 'GET',
        })
        .then(({json}) => json);
    },

    getLocationsExport: () => {
        return httpClient(`${apiUrl}/locations/export`, {
            method: 'GET',
        })
        .then(({json}) => json);
    }
};

const filterDataUpdateTariffgroup = (data: any) => {
    return {
        tariffgroup_id: data.tariffgroup_id,
        subtariffs: data.subtariffs.map((subtariff: any) => {
            return {
                tariffkey: subtariff.tariffkey,
                tariff: subtariff.tariff
            }
        })
    }
}

export default Provider;
