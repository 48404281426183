import {
    DatagridConfigurable,
    DateField, FilterButton, FunctionField,
    List, SelectColumnsButton,
    TextField, TopToolbar
} from "react-admin";
import SearchHintInput from "../../../components/input/SearchHintInput";
import {Box, Container, Typography} from "@mui/material";
import ListIcon from "@mui/icons-material/ElectricalServices";
import {Link} from "react-router-dom";
import Button from "@mui/material/Button";
import GenerateInvoicesButton from "./widgets/GenerateInvoicesButton";
import {Issuer} from "../../../data/facturation/issuer";
import {toEuro} from "../../../../helpers/helper";
import {ButtonInvoicingParameters} from "./widgets/ButtonInvoicingParameters";

const ListActions = () => {
    return (
        <TopToolbar>
            <GenerateInvoicesButton/>
            <SelectColumnsButton/>
            <FilterButton/>
            <ButtonInvoicingParameters/>
        </TopToolbar>
    );
};

const listFilters = [
    <SearchHintInput source="q" alwaysOn key={'search'} hint={"Rechercher une chaine de caracteres."} />,
];

const EmptyList = () => {
    return (
        <Container sx={{display: 'flex', justifyContent: 'center', opacity: 0.5}} className={'RaEmpty-message'}>
            <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <ListIcon className={'RaEmpty-icon'} sx={{width: '9em', height: '9em'}}/>
                <Typography variant="h4" paragraph>
                    Aucun issuer ne correspond à vos critères
                </Typography>
                <Box sx={{marginTop: 5}}>
                    <GenerateInvoicesButton/>
                </Box>
            </Box>
        </Container>
    );
};

export const IssuerList = () => {
    return (
        <List actions={<ListActions/>} filters={listFilters} empty={<EmptyList/>}>
            <DatagridConfigurable rowClick={"show"} bulkActionButtons={false}
                                  omit={[]}>
                <TextField source="reference" sortable={false}/>
                <FunctionField render={(record: Issuer) => (
                    <Button component={Link}
                            onClick={(e) => e.stopPropagation()}
                            to={{
                                pathname: '/cdrs',
                                search: `filter=${JSON.stringify({ issuer: record.reference })}`,
                            }}>
                        CDRs
                    </Button>)} label={'CDRs'}
                />
                <FunctionField render={(record: Issuer) => {
                    if (record.invoices) {
                        return `${record.invoices[0].period.year}-${record.invoices[0].period.month + 1}`;
                    }

                    return null;
                }} label="Dernière période" />
                <FunctionField render={(record: Issuer) => {
                    if (record.invoices) {
                        return toEuro(record.invoices[0].totalCost);
                    }

                    return null;
                }} label="Montant dernière période" />
                <FunctionField render={(record: Issuer) => {
                    if (record.invoices) {
                        return record.invoices[0].totalEnergy.toFixed(2) + ' kWh';
                    }

                    return null;
                }} label="Énergie dernière période" />
                <DateField source="created" showTime={true} sortable={false}/>
            </DatagridConfigurable>
        </List>
    );
};
