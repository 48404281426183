import E55cLogo from "../themes/e55c/toolbar-logo.png";
import Yes55logo from "../themes/yes55/toolbar-logo.png";
import GcaLogo from "../themes/gca/toolbar-logo.png";
import {AppBar, Layout, TitlePortal} from "react-admin";
import Box from "@mui/material/Box";

import {useEffect} from "react";
import AppMenu from "./AppMenu";
import appConfig from "../../config";

const tenant = appConfig.TENANT;

const MyAppBar = () => {
    let logo = null;

    switch (tenant) {
        case 'e55c':
            logo = E55cLogo;
            break;

        case 'yes55':
            logo = Yes55logo;
            break;

        case 'gca':
            logo = GcaLogo;
            break;
    }

    useEffect(() => {
        let linkFavicon = (document.getElementById("app_favicon") as HTMLLinkElement);
        if (linkFavicon) linkFavicon.href = `${tenant ?? ''}/favicon.ico`;

        let metadesc = (document.querySelector("head meta[name=description]") as HTMLMetaElement);
        if (metadesc) metadesc.content = `Admin for ${tenant}`;

        let linkAppleIcon = (document.querySelector("link[rel=apple-touch-icon]") as HTMLLinkElement);
        if (linkAppleIcon) linkAppleIcon!.href = `${tenant ?? ''}/apple-touch-icon.png`;

        let linkIcon32 = (document.getElementById("app_icon32") as HTMLLinkElement);
        if (linkIcon32) linkIcon32!.href = `${tenant ?? ''}/favicon-32x32.png`;

        let linkIcon16 = (document.getElementById("app_icon16") as HTMLLinkElement);
        if (linkIcon16) linkIcon16!.href = `${tenant ?? ''}/favicon-16x16.png`;

        let manifest = (document.querySelector("link[rel=manifest]") as HTMLLinkElement);
        if (manifest) manifest!.href = `${tenant ?? ''}/site.webmanifest`;

        let maskIcon = (document.querySelector("link[rel=mask-icon]") as HTMLLinkElement);
        if (maskIcon) maskIcon!.href = `${tenant ?? ''}/safari-pinned-tab.svg`;

        let headTitle = (document.querySelector("head title") as HTMLElement);
        if (headTitle) headTitle.textContent = `Admin for ${tenant}`;
    }, []);

    return (
        <AppBar elevation={1}>
            <TitlePortal/>
            <Box flex={1}/>
            <Box component="img" src={logo!} sx={{height: 48, marginRight: "1em"}}/>
            <Box flex={2}/>
        </AppBar>
    );
};

const AppLayout = (props: any) => {
    return <Layout  {...props} appBar={MyAppBar} menu={AppMenu}/>;
};

export default AppLayout;
