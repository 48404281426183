import * as React from "react";
import Paper from '@mui/material/Paper';
import {Title, usePermissions} from 'react-admin';
import Box from "@mui/material/Box";
import {Typography} from "@mui/material";
import illuDashboard from "../../assets/images/illu_dashboard.svg";
import StatsEmsp from "./dashboard/StatsEmsp";
import {AppPermissions, hasPermission} from "../../helpers/permissions";
import StatsInvoicing from "./dashboard/StatsInvoicing";

const HeroCard = () => (<Paper sx={{
    padding: 2,
    marginY: 2,
    color: (theme) => theme.palette.getContrastText(theme.palette.primary.dark),
    background: (theme) => `linear-gradient(to right bottom, ${theme.palette.primary.dark}, ${theme.palette.primary.light})`,
    display: 'flex',
    justifyContent: 'space-between'
}}>
    <Box>
        <Box>
            <Typography variant="h2" sx={{fontSize: '1.5em'}}>Tableau de bord</Typography>
        </Box>
        <Box>Bienvenue sur votre interface de gestion eMSP</Box>
    </Box>
    <Box sx={{
        display: {xs: 'none', md: 'block'}
    }}
    >
        <img src={illuDashboard} height={100} alt={''}/>
    </Box>
</Paper>);

const Dashboard = () => {
    const { permissions } = usePermissions();

    return (<>
            <Title title="Tableau de bord"/>
            <HeroCard/>
            {hasPermission(AppPermissions.BadgesManage, permissions) && <StatsEmsp/>}
            {hasPermission(AppPermissions.CdrsView, permissions) && <StatsInvoicing/>}
        </>
    );
};

export default Dashboard;
