import {StringMap} from "ra-core/src/i18n/TranslationMessages";

const frMessages: StringMap = {
  country: "pays",
  badge: {
    error: {
      uid_is_not_unique: "Cet uid est déjà utilisé",
    },
  },
  location: {
    column: {
      opendata: {
        enable_sync: 'Sync opendata'
      }
    },
    opendata: {
      intro: 'Spécifiez ici les valeurs de l\'export opendata non déductibles des infos de la location',
      field: {
        id_station_itinerance: {
          label: 'id station itinerance',
        },
        implantation_station: {
          label: 'implantation station',
        },
        adresse_station: {
          label: 'adresse station',
        },
        gratuit: {
          label: 'gratuit',
        },
        paiement_acte: {
          label: 'paiement acte',
        },
        paiement_cb: {
          label: 'paiement cb',
        },
        paiement_autre: {
          label: 'paiement autre',
        },
        tarification: {
          label: 'tarification',
        },
        condition_acces: {
          label: 'condition d\'accès',
        },
        reservation: {
          label: 'reservation',
        },
        horaires: {
          label: 'horaires',
        },
        accessibilite_pmr: {
          label: 'accessibilite pmr',
        },
        restriction_gabarit: {
          label: 'restriction de gabarit',
        },
        station_deux_roues: {
          label: 'station deux roues',
        },
        raccordement: {
          label: 'raccordement',
        },
        date_mise_en_service: {
          label: 'date de mise en service',
        },
        observations: {
          label: 'observations',
        },
        code_insee_commune: {
          label: 'Code insee commune',
          not_found: 'Code insee de la commune non trouvé pour cette adresse'
        },
        enable_sync: {
          label: 'Integrer dans l\'export opendata'
        },
      }
    }
  },
  cdr: {
    uid: "Badge",
    start_date_time: "Début de charge",
    stop_date_time: "Fin de charge",
    total_cost: "Coût",
    total_energy: "Énergie",
    total_time: "Temps",
    total_parking_time: "Parking",
    last_updated: "last_updated",
  },
  error: {
    form: {
      validation: "Le formulaire n'est pas valide",
    },
  },
};

export default frMessages;
