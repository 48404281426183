import {
  ArrayField,
  BooleanField, ChipField,
  DatagridConfigurable,
  DateField, ExportButton,
  FilterButton,
  List,
  SelectColumnsButton, SingleFieldList,
  TextField,
  TopToolbar
} from 'react-admin';
import React from "react";

import SearchHintInput from "../../components/input/SearchHintInput";
import exporter from "./exporter";
import ExportAllButton from "./components/ExportAllButton";

const listFilters = [
  <SearchHintInput source="q" alwaysOn key={'search'} hint={"Rechercher une chaine de caracteres (sensible à la casse) parmi : id, nom, ville, code postal."} />,
];

const ListActions = () => {
  return (
    <TopToolbar>
        <SelectColumnsButton/>
        <FilterButton/>
        <ExportButton/>
        <ExportAllButton/>
    </TopToolbar>
  );
};

export const LocationList = () => {

  return (
    <List actions={<ListActions/>} filters={listFilters} exporter={exporter}>
      <DatagridConfigurable rowClick="show" bulkActionButtons={false}
                            omit={[
                              'address',
                              'postal_code',
                              'address',
                              'country',
                              'latitude',
                              'longitude',
                              'connectors',
                              'evses',
                            ]}>
        <TextField source="name" sortable={false}/>
        <TextField source="address" sortable={false}/>
        <TextField source="postal_code" sortable={false}/>
        <TextField source="city" sortable={false}/>
        <TextField source="country" sortable={false}/>
        <TextField source="latitude" sortable={false}/>
        <TextField source="longitude" sortable={false}/>
        <TextField source="type" sortable={false}/>
        <BooleanField source="valid" looseValue={true} sortable={false}/>
        <BooleanField source="enable" looseValue={true} sortable={false}/>
        <ArrayField label="EVSES" source="evses">
          <SingleFieldList>
            <ChipField source="id" />
          </SingleFieldList>
        </ArrayField>
        <ArrayField label="Connecteurs" source="connectors">
          <SingleFieldList>
            <ChipField source="id" />
          </SingleFieldList>
        </ArrayField>
        <DateField source="last_updated" showTime={true} sortable={false}/>
        <TextField source="id" sortable={false}/>
      </DatagridConfigurable>
    </List>
  );
};
