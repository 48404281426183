import {
    ArrayInput, AutocompleteInput,
    BooleanInput,
    DateField,
    Edit, Labeled, ReferenceInput, required, SaveButton, SelectInput,
    SimpleFormIterator,
    TabbedForm,
    TextInput, Toolbar,
} from 'react-admin';

import BadgeType from "../../components/input/BadgeType";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";

const Aside = () => {
    return <Card sx={{marginX: 2}}>
        <CardContent>
            <Box>
                <Labeled label={"Created"} sx={{paddingY: 1}}>
                    <DateField source="created" showTime={true} locales={'fr-FR'}/>
                </Labeled>
            </Box>
            <Box>
                <Labeled label={"Last updated"} sx={{paddingY: 1}}>
                    <DateField source="last_updated" showTime={true} locales={'fr-FR'}/>
                </Labeled>
            </Box>
        </CardContent>
    </Card>
}

const EditToolbar = () => {
    return (<Toolbar>
        <SaveButton/>
    </Toolbar>);
};

export const BadgeEdit = () => (
    <Edit aside={<Aside/>}>
        <TabbedForm toolbar={<EditToolbar />}>
            <TabbedForm.Tab label="Token">
                <TextInput source="uid" validate={required()} disabled/>
                <BadgeType source="type" validate={required()}/>
                <TextInput source="visual_number" validate={required()}/>
                <TextInput source="auth_id" validate={required()} label={'auth_id'}/>
                <TextInput source="issuer" validate={required()}/>
                <SelectInput source="whitelist" choices={[
                    { id: 'ALLOWED', name: 'ALLOWED' },
                    { id: 'ALLOWED_OFFLINE', name: 'ALLOWED_OFFLINE' },
                    { id: 'NEVER', name: 'NEVER' },
                    { id: 'ALWAYS', name: 'ALWAYS' },
                ]}/>
                <BooleanInput source="valid" />
                <TextInput source="id" disabled/>
            </TabbedForm.Tab>

            <TabbedForm.Tab label="Grant">
                <SelectInput source="allowed" choices={[
                    { id: 'ALLOWED', name: 'ALLOWED' },
                    { id: 'BLOCKED', name: 'BLOCKED' },
                    { id: 'EXPIRED', name: 'EXPIRED' },
                    { id: 'NO_CREDIT', name: 'NO_CREDIT' },
                    { id: 'NOT_ALLOWED', name: 'NOT_ALLOWED' },
                ]} validate={required()}/>
            </TabbedForm.Tab>

            <TabbedForm.Tab label="locations">
                <ArrayInput source="locations">
                    <SimpleFormIterator fullWidth={true}>
                        <ReferenceInput reference="locations" source={'id'}>
                            <AutocompleteInput optionText="name" label={'Location'} fullWidth={true}/>
                        </ReferenceInput>
                    </SimpleFormIterator>
                </ArrayInput>
            </TabbedForm.Tab>
        </TabbedForm>
    </Edit>
);
