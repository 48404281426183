import {useEffect, useState} from "react";
import CardWithIcon from "./CardWithIcon";
import {BadgeIcon, LocationIcon} from "../../constants";
import * as React from "react";
import {useDataProvider, useNotify} from "react-admin";
import DashboardSection from "./DashboardSection";

type StatsResponse = {
    badges: number;
    locations: number;
}

export default function StatsEmsp() {
    const [stats, setStats] = useState<StatsResponse>();
    const [loading, isLoading] = useState(true);
    const provider = useDataProvider();
    const notify = useNotify();

    useEffect(() => {
        provider.getEmspStats()
            .then((resp: React.SetStateAction<StatsResponse | undefined>) => setStats(resp))
            .catch((err: any) => notify(err.toString(), {type: 'error'}))
            .finally(() => isLoading(false))
    }, [provider, notify]);

    return (<DashboardSection title={'eMSP'}>
        <CardWithIcon icon={BadgeIcon} to={'/badges'} title={'Badges'} subtitle={loading ? '-' : stats?.badges}/>
        <CardWithIcon icon={LocationIcon} to={'/locations'} title={'Locations'} subtitle={loading ? '-' : stats?.locations}/>
    </DashboardSection>);
}
