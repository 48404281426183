import {
    SimpleShowLayout, Show, TextField, BooleanField, DateField, TopToolbar, ListButton, ArrayField, Datagrid
} from 'react-admin';

import {ChevronLeft} from '@mui/icons-material';
import React from "react";

const Actions = () => {
    return (
        <TopToolbar>
            <ListButton icon={<ChevronLeft/>}/>
        </TopToolbar>
    );
};

export const LocationShow = () => (
    <Show actions={<Actions/>}>
      <SimpleShowLayout>
          <TextField source="id"/>
          <TextField source="name"/>
          <TextField source="address"/>
          <TextField source="postal_code"/>
          <TextField source="city"/>
          <TextField source="country"/>
          <TextField source="latitude"/>
          <TextField source="longitude"/>
          <TextField source="type"/>
          <BooleanField source="valid" looseValue={true}/>
          <BooleanField source="enable" looseValue={true}/>
          <DateField source="last_updated" showTime={true}/>
          <h3>Evses</h3>
          <ArrayField source="evses" label={false}>
              <Datagrid bulkActionButtons={false}>
                  <TextField source="id" />
                  <TextField source="status" />
                  <DateField source="last_updated" showTime={true}/>
              </Datagrid>
          </ArrayField>
          <h3>Connecteurs</h3>
          <ArrayField source="connectors" label={false}>
              <Datagrid bulkActionButtons={false}>
                  <TextField source="id" />
                  <TextField source="evse_id" />
                  <TextField source="amperage" />
                  <TextField source="format" />
                  <DateField source="last_updated" showTime={true}/>
                  <TextField source="power_type" />
                  <TextField source="standard" />
                  <TextField source="voltage" />
              </Datagrid>
          </ArrayField>
      </SimpleShowLayout>
    </Show>
);
