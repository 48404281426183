import {
    CreateButton,
    Datagrid,
    FilterButton,
    List,
    TextField,
    TopToolbar
} from 'react-admin';
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import React from "react";
import SearchHintInput from "../../components/input/SearchHintInput";


const listFilters = [
    <SearchHintInput source="q" alwaysOn key={'search'} hint={"Rechercher une chaine de caracteres dans l'id des groupes."} />,
];

const ListActions = () => {
    return (
        <TopToolbar>
            <FilterButton/>
            <CreateButton/>
        </TopToolbar>
    );
};
export const TariffgroupList = () => {
    return (
        <Box>
            <Card sx={{marginTop: 3}}>
                <CardContent>Sur cette page, vous pouvez gérer vos tariff groupes, que vous pourrez utiliser ensuite pour créer des tariff links</CardContent>
            </Card>
            <List actions={<ListActions/>} filters={listFilters}>
                <Datagrid rowClick="edit" bulkActionButtons={false}>
                    <TextField source="tariffgroup_id" label={"Tariff group"}/>
                </Datagrid>
            </List>
        </Box>
    );
};
