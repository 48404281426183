import jsonExport from "jsonexport/dist";
import {downloadCSV} from "react-admin";

import {Location} from "../../data/location";

export default function exporter(records: Location[]) {
    const rows :any[] = [];

    records.forEach((location) => {
        const locationBase = {
            name: location.name,
            id: location.id,
            country: '',
            city: location.city,
            postal_code: location.postal_code,
            address: location.address,
            latitude: location.latitude,
            longitude: location.longitude,
        }
        if (!location.connectors) {
            if (!location.evses) {
                rows.push(locationBase);
            }
            else {
                location.evses.forEach((evse) => {
                    rows.push({
                        ...locationBase,
                        evse: {
                            id: evse.id,
                            status: evse.status,
                        }
                    });
                })
            }
        }
        else {
            location.connectors.forEach((connector) => {
                const evse = location.evses?.find(evse => evse.id === connector.evse_id);

                rows.push({
                    location: locationBase,
                    evse: evse ? {
                        id: evse.id,
                        status: evse.status,
                    } : null,
                    id: connector.id,
                    standard: connector.standard,
                    format: connector.format,
                    power_type: connector.power_type,
                    voltage: connector.voltage,
                    amperage: connector.amperage,
                });
            })
        }
    });

    jsonExport(
        rows,
        {
            fillGaps: true,
            rowDelimiter: ';',
            // mapHeaders: (header: string) : string => {
            //     return header;
            // },
            headers: [
                'location.name',
                'location.id',
                'location.country',
                'location.city',
                'location.postal_code',
                'location.address',
                // 'house_number',
                'location.latitude',
                'location.longitude',
                'evse.id',
                'id',
                'evse.status',
                'standard',
                'format',
                'power_type',
                'voltage',
                'amperage',
            ]
        },
        (err: any, csv: any) => {
            downloadCSV(csv, 'locations'); // download as 'posts.csv` file
        });
};
