import {
    AutocompleteInput,
    CreateButton,
    Datagrid, DeleteButton,
    FilterButton,
    List, ReferenceField, ReferenceInput,
    TextField,
    TopToolbar
} from 'react-admin';
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import React from "react";
import SearchHintInput from "../../components/input/SearchHintInput";


const listFilters = [
    <SearchHintInput source="q" alwaysOn key={'search'} hint={"Rechercher une chaine de caracteres parmi: group_id, party_id, location_id."} />,
    <ReferenceInput reference="tariffemsps"  source="party_id" label={'EMSP'}>
        <AutocompleteInput optionText="party_id" optionValue="party_id" fullWidth={true}/>
    </ReferenceInput>,
    <ReferenceInput reference="tarifflocations"  source="location_id" label={'Location'}>
        <AutocompleteInput optionText="name" optionValue="id" fullWidth={true}/>
    </ReferenceInput>
];

const ListActions = () => {
    return (
        <TopToolbar>
            <FilterButton/>
            <CreateButton/>
        </TopToolbar>
    );
};

// const TarifflinkDeleteButton = () => {
//     const record = useRecordContext();
//     const [deleteOne, { isLoading, error }] = useDelete();
//     const handleClick = () => {
//         deleteOne(
//             'likes',
//             { id: record.id , previousData: record }
//         );
//     }
//     if (error) { return <p>ERROR</p>; }
//     return <button disabled={isLoading} onClick={handleClick}>Delete</button>;
// };

export const TarifflinkList = () => {
    return (
        <Box>
            <Card sx={{marginTop: 3}}>
                <CardContent>Sur cette page, vous pouvez gérer vos tariff links</CardContent>
            </Card>
            <List actions={<ListActions/>} filters={listFilters}>
                <Datagrid rowClick="" bulkActionButtons={false}>
                    <ReferenceField source="tariffgroup_id" reference="tariffgroups" label={"Tariff group"} link={'show'}>
                        <TextField source="tariffgroup_id" />
                    </ReferenceField>
                    <ReferenceField source="party_id" reference="tariffemsps" label={"EMSP"}>
                        <TextField source="party_id" />
                    </ReferenceField>
                    <TextField source="location_id" />
                    <DeleteButton />
                </Datagrid>
            </List>
        </Box>
    );
};
