import {
    AutocompleteInput,
    Create,
    ReferenceInput, required, SimpleForm,
} from 'react-admin';

export const TarifflinkCreate = () => (
    <Create>
        <SimpleForm>
            <ReferenceInput reference="tariffgroups" source="tariffgroup_id" validate={required()}>
                <AutocompleteInput optionText="tariffgroup_id" optionValue="tariffgroup_id" label={'Tariff group'} validate={required()}/>
            </ReferenceInput>
            <ReferenceInput reference="tariffemsps"  source="party_id" validate={required()}>
                <AutocompleteInput optionText="party_id" optionValue="party_id" label={'EMSP'} validate={required()}/>
            </ReferenceInput>
            <ReferenceInput reference="tarifflocations"  source="location_id" validate={required()}>
                <AutocompleteInput optionText="name" optionValue="id"  label={'Location'} validate={required()} sx={{minWidth: '300px'}}/>
            </ReferenceInput>
        </SimpleForm>
    </Create>
);
