import {
    ArrayField, AutocompleteInput,
    BooleanField, ChipField,
    CreateButton,
    DatagridConfigurable,
    DateField,
    FilterButton,
    List, ReferenceInput,
    SelectColumnsButton, SingleFieldList,
    TextField,
    TopToolbar
} from 'react-admin';
import React from "react";
import SearchHintInput from "../../components/input/SearchHintInput";
import ExportAllButton from "./components/ExportAllButton";


const listFilters = [
    <SearchHintInput source="q" alwaysOn key={'search'} hint={"Rechercher une chaine de caracteres (sensible à la casse) parmi : uid, issuer, visual number."} />,
    <ReferenceInput source="location_id" reference={"locations"} >
        <AutocompleteInput optionText="id" optionValue="id" label={'Location'} sx={{minWidth: '300px'}}/>
    </ReferenceInput>
];

const ListActions = () => {
    return (
        <TopToolbar>
            <FilterButton/>
            <CreateButton/>
            <SelectColumnsButton/>
            <ExportAllButton />
        </TopToolbar>
    );
};

export const BadgeList = () => {
    return (
        <List actions={<ListActions/>} filters={listFilters}>
            <DatagridConfigurable rowClick="edit" bulkActionButtons={false} omit={['created, locations, whitelist, allowed']}>
                <TextField source="uid"/>
                <TextField source="type"/>
                <TextField source="visual_number"/>
                <TextField source="issuer"/>
                <ArrayField label="Locations" source="locations">
                    <SingleFieldList linkType={false}>
                        <ChipField source="id" />
                    </SingleFieldList>
                </ArrayField>
                <TextField source="auth_id" label={'Auth id'}/>
                <TextField source="whitelist" label={'Whitelist'}/>
                <TextField source="allowed" label={'Grant'}/>
                <BooleanField source="valid" looseValue={true}/>
                <DateField source="last_updated" showTime={true} locales={'fr-FR'}/>
                <DateField source="created" showTime={true} locales={'fr-FR'}/>
            </DatagridConfigurable>
        </List>
    );
};
