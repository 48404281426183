import {Menu, usePermissions} from "react-admin";
import {AppPermissions, hasPermission} from "../../helpers/permissions";

import BadgeIcon from "@mui/icons-material/CreditCard";
import React from "react";

import TariffsIcon from "@mui/icons-material/EuroSymbol";
import ExploitationIcon from '@mui/icons-material/Work';
import FacturationIcon from '@mui/icons-material/Receipt';
import appConfig from "../../config";
import Submenu from "./Submenu";

const tariffsEnabled = parseInt(appConfig.TARIFFS_ENABLED);

export default function AppMenu() {
    const { permissions } = usePermissions();
    const showMenuTariffs = tariffsEnabled && (hasPermission(AppPermissions.TariffsManage, permissions)
        || hasPermission(AppPermissions.TariffLinkManage, permissions));
    const showMenuExploitation = hasPermission(AppPermissions.CdrsView, permissions)
        || hasPermission(AppPermissions.CdrsSync, permissions)
        || hasPermission(AppPermissions.BadgesManage, permissions);
    const showMenuFacturation = showMenuExploitation;

    return <Menu>
        <Menu.DashboardItem primaryText={'Tableau de bord'}/>
        {hasPermission(AppPermissions.BadgesManage, permissions) &&
            <Submenu text={'eMSP'} icon={<BadgeIcon />}>
                <Menu.ResourceItem name="badges" />
                <Menu.ResourceItem name="locations" />
            </Submenu>}
        {showMenuTariffs &&
            <Submenu text={'Tariffs'} icon={<TariffsIcon />}>
                {tariffsEnabled && hasPermission(AppPermissions.TariffsManage, permissions) ? <Menu.ResourceItem name="tariffgroups" /> : null}
                {tariffsEnabled && hasPermission(AppPermissions.TariffLinkManage, permissions) ? <Menu.ResourceItem name="tarifflinks" /> : null}
            </Submenu>}
        {showMenuExploitation &&
            <Submenu text={'Exploitation'} icon={<ExploitationIcon />}>
                {hasPermission(AppPermissions.CdrsView, permissions) && <Menu.ResourceItem name="cdrs" />}
                {hasPermission(AppPermissions.CdrsSync, permissions) && <Menu.ResourceItem name="cdr-import-jobs" />}
                {hasPermission(AppPermissions.BadgesManage, permissions) && <Menu.ResourceItem name="exploitation/locations" />}
            </Submenu>}
        {showMenuFacturation &&
            <Submenu text={'Facturation'} icon={<FacturationIcon />}>
                {<Menu.ResourceItem name="invoicing/issuers" />}
            </Submenu>}
    </Menu>
}
