import {Button, useDataProvider} from 'react-admin';
import {useState} from "react";
import CircularProgress from "@mui/material/CircularProgress";

const ExportAllButton = () => {
  const dataProvider = useDataProvider();
  const [loading, setLoading] = useState(false);

  const handleDl = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    setLoading(true);

    dataProvider.getLocationsExport()
      .then((response: any) => {
        const blob = new Blob([JSON.stringify(response)], { type: 'application/json' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'locations.json';
        link.click();
        URL.revokeObjectURL(link.href);
        link.remove();
      })
      .catch((err: any) => alert(err.message))
      .finally(() => setLoading(false));
  }

  if (loading) {
    return <CircularProgress
      size={18}
      thickness={2}
    />
  }

  return <Button onClick={handleDl} label={'Export global'} variant={'outlined'}/>
}

export default ExportAllButton;
