import {
    ArrayInput, AutocompleteInput,
    BooleanInput,
    Create,
    ReferenceInput, required, SelectInput,
    SimpleFormIterator, TabbedForm,
    TextInput
} from 'react-admin';
import BadgeType from "../../components/input/BadgeType";

export const BadgeCreate = () => (
    <Create>
        <TabbedForm>
            <TabbedForm.Tab label="Token">
                <TextInput source="uid" validate={required()}/>
                <BadgeType source="type" validate={required()}/>
                <TextInput source="visual_number" validate={required()}/>
                <TextInput source="auth_id" validate={required()} label={'auth_id'}/>
                <TextInput source="issuer" validate={required()}/>
                <SelectInput source="whitelist" choices={[
                    { id: 'ALLOWED', name: 'ALLOWED' },
                    { id: 'ALLOWED_OFFLINE', name: 'ALLOWED_OFFLINE' },
                    { id: 'NEVER', name: 'NEVER' },
                    { id: 'ALWAYS', name: 'ALWAYS' },
                ]}/>
                <BooleanInput source="valid" />
            </TabbedForm.Tab>

            <TabbedForm.Tab label="Grant">
                <SelectInput source="allowed" choices={[
                    { id: 'ALLOWED', name: 'ALLOWED' },
                    { id: 'BLOCKED', name: 'BLOCKED' },
                    { id: 'EXPIRED', name: 'EXPIRED' },
                    { id: 'NO_CREDIT', name: 'NO_CREDIT' },
                    { id: 'NOT_ALLOWED', name: 'NOT_ALLOWED' },
                ]} validate={required()}/>
            </TabbedForm.Tab>


            <TabbedForm.Tab label="locations">
                <ArrayInput source="locations">
                    <SimpleFormIterator>
                        <ReferenceInput reference="locations" source={'id'}>
                            <AutocompleteInput optionText="name" />
                        </ReferenceInput>
                    </SimpleFormIterator>
                </ArrayInput>
            </TabbedForm.Tab>
        </TabbedForm>
    </Create>
);
